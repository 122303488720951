<template>
  <div class="col responsive-width">
    <div class="container-fluid" v-if="pageLoading === false">
      <div class="content">
        <div class="headers d-flex">
          <h1 class="mb-0">{{ $t("invoices") }}</h1>
          <h6>{{ $t("edit_invoice") }}</h6>
        </div>
        <div class="invoice-numbers flex-wrap justify-content-between">
          <h2 class="mb-0">
            {{ $t("invoice") }}
            <strong> {{ invoiceData.document_number }}</strong>
          </h2>
          <div class="numbers my-2 flex-wrap">
            <p>{{ $t("ocr_number") }}: {{ invoiceData.ocr }}</p>
            <p>{{ $t("voucher_numbers") }}: {{ invoiceData.voucher_number }}</p>
          </div>
        </div>
        <div class="box invoice-details">
          <h3>{{ $t("invoice_detail") }}</h3>
          <form class="position-relative">
            <!-- <img src="./../../assets/images/table/edit-chat.png"> -->
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("customer_number") }}</label>
                <input
                  @input="searchField"
                  class="big"
                  type="text"
                  v-model="invoiceData.customer_number"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
                <div
                  v-if="showCustomer"
                  style="z-index: 9999; width: 12%"
                  class="position-absolute bg-white rounded shadow-md border mt-5"
                >
                  <ul
                    style="padding-left: 0rem !important; list-style-type: none"
                  >
                    <li
                      class="border-bottom p-2 mb-1 cursor-pointer"
                      v-for="item in searchCustomer.slice(0, 5)"
                      :key="item.id"
                      @click="chooseCustomer(item)"
                    >
                      {{ item.customer_number }} - {{ item.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("invoice_date") }}</label>
                <input
                  type="date"
                  v-model="invoiceData.invoice_date"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
              </div>
              <div class="desc">
                <label>{{ $t("due_date") }}</label>
                <input
                  type="date"
                  v-model="invoiceData.due_date"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
              </div>
              <div class="desc">
                <label>{{ $t("invoice") }} {{ $t("type") }}</label>
                <div class="radio">
                  <input
                    type="radio"
                    v-model="invoiceData.invoice_type"
                    value="INVOICE"
                    name="invoice-type"
                    id="type-yes"
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="type-yes">{{ $t("invoice") }}</label>
                  <input
                    type="radio"
                    v-model="invoiceData.invoice_type"
                    value="CASH"
                    name="invoice-type"
                    id="type-no"
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="type-no">{{ $t("cash_invoice") }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("credit_invoice") }}</label>
                <div class="radio">
                  <input
                    type="radio"
                    v-model="invoiceData.credit"
                    :value="true"
                    name="active"
                    id="ac-yes"
                    checked
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="ac-yes">{{ $t("yes") }}</label>
                  <input
                    type="radio"
                    v-model="invoiceData.credit"
                    :value="false"
                    name="active"
                    id="ac-no"
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="ac-no">{{ $t("no") }}</label>
                </div>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <div class="desc">
                <label>{{ $t("terms_of_payment") }}</label>
                <select
                  v-model="invoiceData.terms_of_payment"
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                >
                  <option value=""></option>
                  <option
                    :value="item.code"
                    v-for="item in paymentTerm"
                    :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("our_reference") }}</label>
                <input
                  type="text"
                  v-model="invoiceData.our_reference"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
              </div>
              <!--              <div class="desc">-->
              <!--                <label>Your order date</label>-->
              <!--                <input type="text" :readonly='invoiceData.booked || invoiceData.cancelled'>-->
              <!--              </div>-->
              <div class="desc">
                <label>{{ $t("your_order_number") }}</label>
                <input
                  type="text"
                  v-model="invoiceData.your_order_number"
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
              </div>
              <div class="desc">
                <label>{{ $t("terms_of_delivery") }}</label>
                <select
                  v-model="invoiceData.terms_of_delivery"
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                >
                  <option value=""></option>
                  <option
                    :value="item.code"
                    v-for="item in deliveryTerm"
                    :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("way_of_delivery") }}</label>
                <select
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                  v-model="invoiceData.way_of_delivery"
                >
                  <option value=""></option>
                  <option
                    :value="item.code"
                    v-for="item in deliveryWay"
                    :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="d-flex flex-wrap col-flex-wrap">
              <!-- <div class="desc">
                <label>{{ $t('your_order_number') }}</label>
                <input type="text" v-model="invoiceData.your_order_number"
                       :readonly='invoiceData.booked || invoiceData.cancelled'>
              </div> -->
              <div class="desc">
                <label>{{ $t("your_reference") }}</label>
                <input
                  type="text"
                  v-model="invoiceData.your_reference"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                />
              </div>
              <div class="desc">
                <label>{{ $t("price_list") }}</label>
                <select
                  v-model="invoiceData.price_list"
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                >
                  <option value=""></option>
                  <option
                    :value="item.code"
                    v-for="item in pricelist"
                    :key="item.id"
                  >
                    {{ item.description }}
                  </option>
                </select>
              </div>
              <div class="desc">
                <label>{{ $t("vat_included") }}</label>
                <div class="radio">
                  <input
                    type="radio"
                    v-model="invoiceData.vat_included"
                    :value="true"
                    name="vat"
                    id="vat-yes"
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="vat-yes">{{ $t("yes") }}</label>
                  <input
                    type="radio"
                    v-model="invoiceData.vat_included"
                    :value="false"
                    name="vat"
                    id="vat-no"
                    :disabled="invoiceData.booked || invoiceData.cancelled"
                    :readonly="invoiceData.booked || invoiceData.cancelled"
                  />
                  <label for="vat-no">{{ $t("no") }}</label>
                </div>
              </div>
              <div class="desc">
                <label>{{ $t("currency") }}</label>
                <select
                  v-model="invoiceData.currency"
                  :disabled="invoiceData.booked || invoiceData.cancelled"
                  :readonly="invoiceData.booked || invoiceData.cancelled"
                >
                  <option value=""></option>
                  <option
                    :value="item.code"
                    v-for="item in currency"
                    :key="item.id"
                  >
                    {{ item.code }}
                  </option>
                </select>
              </div>
              <!-- <div class="desc">
                <label>Rate</label>
                <input v-model="invoiceData.currency_rate" type="text" readonly>
              </div>
              <div class="desc">
                <label>Unit</label>
                <input v-model="invoiceData.unit" type="text" readonly>
              </div> -->
              <!-- <div class="desc">
                <label>VAT type</label>
                <select>
                  <option></option>
                </select>
              </div> -->
            </div>
          </form>
        </div>
        <div class="row vertical-desc">
          <div class="col">
            <div class="box customer-details">
              <h3>{{ $t("customer_detail") }}</h3>
              <form class="row g-0">
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("name") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.customer_name"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("invoice_address") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.address1"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("invoice_address") }} 2 </label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.address2"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("zip_code") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.zip_code"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.city"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("organization_number") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.organisation_number"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("phone") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.phone1"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("email") }}</label>
                    <input
                      v-if="invoiceData.email_information"
                      class="big"
                      type="email"
                      :value="invoiceData.email_information.email_address_to"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.city"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("country") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.country"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col">
            <div class="box delivery-details">
              <h3>{{ $t("delivery_detail") }}</h3>
              <form class="row g-0">
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("name") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.delivery_name"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("delivery_address") }} </label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.delivery_address1"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("delivery_address") }} 2 </label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.delivery_address2"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("zip_code") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.delivery_zip_code"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.delivery_city"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="desc">
                    <label>{{ $t("delivery_date") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.delivery_date"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("phone") }}</label>
                    <input
                      class="big"
                      type="text"
                      :value="invoiceData.phone2"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="desc">
                    <label>{{ $t("email") }}</label>
                    <input
                      v-if="invoiceData.email_information"
                      class="big"
                      type="text"
                      :value="invoiceData.email_information.email_address_from"
                      :readonly="invoiceData.booked || invoiceData.cancelled"
                    />
                  </div>
                  <div class="parent-small d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("city") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.delivery_city"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                    <div class="desc">
                      <label>{{ $t("country") }}</label>
                      <input
                        class="small"
                        type="text"
                        :value="invoiceData.delivery_country"
                        :readonly="invoiceData.booked || invoiceData.cancelled"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <custom-invoice-row-component
          :invoice_rows="invoiceData.invoice_rows"
          :invoice_country="CURRENT_CUSTOMER.country_code"
          :help-text="helpText"
          @removeRow="removeRow"
          :key="sortKey"
          @calculateRow="reCalculateValues"
          :booked="invoiceData.booked"
          :cancelled="invoiceData.cancelled"
          :vat_included="invoiceData.vat_included"
          :fully_paid="invoiceData.fully_paid"
          @updateContract="updateInvoiceData"
          :submitForm="submitForm"
        />

        <div class="row align-items-center">
          <div class="col-lg-8">
            <div class="box others-details">
              <h3>{{ $t("other_detail") }}</h3>
              <form>
                <div class="row">
                  <div class="col-lg-7">
                    <div class="d-flex flex-wrap col-flex-wrap">
                      <div class="desc me-0 w-full">
                        <label>{{ $t("invoice_text") }}</label>
                        <textarea
                          class="w-full"
                          v-model="invoiceData.remarks"
                          :disabled="invoiceData.cancelled"
                          readonly
                        ></textarea>
                      </div>
                      <div class="desc me-0 w-full">
                        <label>{{ $t("comments") }}</label>
                        <textarea
                          class="w-full"
                          v-model="invoiceData.comments"
                          :disabled="invoiceData.cancelled"
                        >
                        </textarea>
                      </div>
                      <!-- <div class="desc">
                        <label>Shipping charge</label>
                        <input type="text">
                      </div> -->
                      <!-- <div class="desc">
                        <label>Invoice charge</label>
                        <input type="text">
                      </div> -->
                      <!-- <div class="desc">
                        <label>Invoice dicsount %</label>
                        <input type="text">
                      </div> -->
                    </div>
                    <div class="d-flex mt-lg-3 flex-wrap">
                      <!-- <div class="desc">
                        <label>Printout template</label>
                        <select v-model="invoiceData.print_template" class="big">
                            <option v-for="(item, i) in printOutTemplate" :key="i">{{ item }}</option>
                        </select>
                      </div> -->
                      <!-- <div class="desc">
                        <label>Language</label>
                        <select v-model="invoiceData.language">
                          <option></option>
                        </select>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-lg d-flex flex-wrap col-flex-wrap">
                    <div class="desc">
                      <label>{{ $t("print_template") }}</label>
                      <select
                        v-model="invoiceData.print_template"
                        class="big"
                        :disabled="invoiceData.cancelled || invoiceData.booked"
                      >
                        <option
                          v-for="(item, i) in printOutTemplate"
                          :value="item.id"
                          :key="i"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <!-- <div class="desc me-0 w-full">
                      <label>Invoice text</label>
                      <textarea class="w-full" v-model="invoiceData.comments">Thank you for subscring to our service!</textarea>
                    </div>
                    <div class="desc me-0 w-full">
                      <label>Comment</label>
                      <textarea class="w-full" v-model="invoice.comments"> </textarea>
                    </div> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 mx-auto mx-lg-0">
            <div class="box price">
              <ul class="list-unstyled">
                <!-- <li><span class="text">{{ $t('net') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ total_exl_vat }}</span>
                </li>
                <li><span class="text">{{ $t('gross') }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoiceData.gross }}</span>
                </li> -->
                <li>
                  <span class="text">{{ $t("vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoiceData.total_vat }}</span>
                </li>
                <li>
                  <span class="text">{{ $t("total_exclude_vat") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoiceData.total }}</span>
                </li>
                <hr />
                <li>
                  <span class="text">{{ $t("total") }}</span>
                  <div class="border-between"></div>
                  <span class="number">{{ invoiceData.total_to_pay }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <div class="col-lg-4">
            <div class="send">
              <label>{{ $t("send") }} {{ $t("invoice") }}</label>
              <select v-model="action">
                <option value="email">{{ $t("email") }}</option>
                <option value="send">{{ $t("send_via_nox_finans") }}</option>
                <option value="einvoice">{{ $t("e_invoice") }}</option>
                <option value="print">{{ $t("print") }}</option>
                <option v-if="invoiceEligibleForSchedule" value="schedule">
                  {{ $t("schedule_invoice_send_out") }}
                </option>
              </select>
              <button
                type="submit"
                :disabled="sending || invoiceData.cancelled"
                @click="sendingActions"
              >
                <span v-if="sending">{{ $t("sending") }}...</span>
                <span v-else
                  ><span v-if="action === 'credit'">{{
                    $t("send_invoice")
                  }}</span>
                  <span v-else>{{ $t("send") }}</span></span
                >
              </button>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="buttons">
              <router-link class="btn-size" :to="{ name: 'invoices' }">
                {{ $t("cancel") }}</router-link
              >
              <button
                class="btn-size"
                type="submit"
                @click="openCreateCopy"
                :disabled="invoiceData.cancelled"
              >
                <span v-if="processCredit">{{ $t("processing") }}..</span
                ><span v-else> {{ $t("credit_invoice") }}</span>
              </button>
              <button
                class="btn-size"
                type="submit"
                @click="openRegisterModal"
                :disabled="invoiceData.cancelled"
              >
                {{ $t("register_payment") }}
              </button>
              <button
                class="btn-size"
                type="submit"
                :disabled="invoiceData.booked || invoiceData.cancelled"
                @click="openCancelConfirm"
              >
                {{ $services.helpers.capitalizeNames($t("void")) }}
                {{ $t("invoice").toLowerCase() }}
              </button>
              <button
                class="btn-size"
                :disabled="
                  processing || invoiceData.booked || invoiceData.cancelled
                "
                @click="submitForm = true"
                type="submit"
              >
                <span v-if="processing">{{ $t("saving") }}...</span>
                <span v-else>{{ $t("save_invoice") }}</span>
              </button>
              <button
                class="btn-size"
                type="submit"
                :disabled="
                  keeping || invoiceData.booked || invoiceData.cancelled
                "
                @click="updateInvoiceData(true)"
              >
                <!-- <button class="btn-size" type="submit" :disabled="keeping || invoiceData.booked || invoiceData.cancelled"
                      @click="bookkeepInvoice"> -->
                <span v-if="keeping">{{ $t("bookkeeping") }}...</span>
                <span v-else
                  >{{ $t("bookkeep") }} {{ $t("invoice").toLowerCase() }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="content">
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12">
            <div class="box square">
              <shimmer-loading :num="5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalBoxVue
    v-if="openModalSendInvoice"
    @handleClose="openModalSendInvoice = false"
    :close="!true"
  >
    <div class="popup-box2">
      <h4 style="text-align: left !important">{{ $t("send_invoiced") }}</h4>
      <form class="popup-body" @submit.prevent="sendInvoice">
        <label>{{ $t("invoice_type") }}</label>
        <select v-model="invoiceType" required>
          <option value=""></option>
          <option value="fortnox">{{ $t("fortnox_invoice_service") }}</option>
          <option value="regular">{{ $t("regular") }}</option>
        </select>
        <label v-if="invoiceType === 'regular'">{{ $t("send_method") }}</label>
        <select v-if="invoiceType === 'regular'" v-model="sendMethod" required>
          <option value=""></option>
          <option value="email">{{ $t("as_email") }}</option>
          <option value="einvoice">{{ $t("e_invoice") }}</option>
        </select>
        <label v-if="invoiceType === 'fortnox'">{{ $t("send_method") }}</label>
        <select v-if="invoiceType === 'fortnox'" v-model="sendMethod" required>
          <option value=""></option>
          <option value="EMAIL">{{ $t("as_email") }}</option>
          <option value="LETTER">{{ $t("letter") }}</option>
          <!-- <option value="EINVOICE">{{ $t("e_invoice") }}</option> -->
          <option value="NONE">{{ $t("do_not_send_nox") }}</option>
        </select>
        <!--        <label v-if="invoiceType === 'fortnox'">Services</label>-->
        <!--        <select v-if="invoiceType === 'fortnox'" v-model="services" required>-->
        <!--          <option value="LEDGERBASE">Ledgerbase</option>-->
        <!--          <option value="REMINDER">Reminder</option>-->
        <!--          <option value="FACTORING_LOAN">Factoring Loan</option>-->
        <!--          <option value="FACTORING_SELL">Factoring Sell</option>-->
        <!--        </select>-->
        <!-- <label v-if="invoiceType === 'fortnox'">Action</label>
        <select v-if="invoiceType === 'fortnox'" v-model="actionNoxFinance" required>
            <option value="pause">Pause</option>
            <option value="stop">Stop</option>
        </select> -->
        <div class="popup-bottons">
          <button @click="openModalSendInvoice = false">
            {{ $t("cancel") }}
          </button>
          <button :disabled="sending" type="submit">
            <span v-if="sending">{{ $t("Sending") }}...</span>
            <span v-else>{{ $t("send") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <ModalBoxVue
    v-if="openFailedInvoices"
    @handleClose="openFailedInvoices = false"
    :close="!true"
  >
    <div class="popup-box2">
      <h4 style="text-align: left !important">{{ $t("failed_invoices") }}</h4>
      <div class="p-3">
        <h5>
          {{ $t("the") }} {{ $t("following") }} ({{ failedInvoice.length }})
          {{ $t("invoices").toLowerCase() }} {{ $t("failed") }}
        </h5>
        <p v-for="(item, i) in failedInvoice" :key="i">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              style="color: red"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          <span>{{ $t("invoice_number") }}: {{ item.invoice_id }}</span>
        </p>
      </div>
    </div>
  </ModalBoxVue>
  <ModalAlertVue
    v-if="openCancelModal"
    @handleClose="openCancelModal = false"
    :close="!true"
  >
    <h4>
      {{ $t("void_confirmation_text") }}
      <span class="user-confirm text-danger">{{
        $t("invoice").toLowerCase()
      }}</span
      >?
    </h4>
    <div class="popup-bottons">
      <button @click="openCancelModal = false">
        {{ $t("cancel_delete_request") }}
      </button>
      <button :disabled="cancelling" @click="voidInvoice">
        <span v-if="cancelling">{{ $t("voiding") }}...</span>
        <span v-else>{{ $t("accept_delete_request") }}</span>
      </button>
    </div>
  </ModalAlertVue>
  <ModalBoxVue
    v-if="openModalRegisterPayment"
    @handleClose="openModalRegisterPayment = false"
    :close="!true"
  >
    <div class="popup-box2">
      <h4 style="text-align: left !important">{{ $t("register_payment") }}</h4>
      <form class="popup-body" @submit.prevent="registerPayment">
        <label>{{ $t("payment_date") }}</label>
        <input type="date" v-model="paymentDate" required />
        <div class="currency-container">
          <label>{{ $t("payment_amount") }}</label>
          <input
            type="number"
            min="0"
            step="0.1"
            v-model="paymentAmount"
            required
            style="padding-right: 42px"
          />
          <span class="currency" v-if="invoiceData.currency">{{
            invoiceData.currency
          }}</span>
        </div>
        <label>{{ $t("mode_of_payment_account") }}</label>
        <!--        <input type="text" v-model="paymentMode" required>-->
        <input
          @input="searchPaymentAccountField"
          type="text"
          v-model="paymentMode"
        />
        <div
          v-if="showAccountRegister"
          style="z-index: 9999; width: 150px"
          class="position-absolute bg-white rounded shadow-md border p-2"
        >
          <ul style="padding-left: 0.3rem !important; list-style-type: none">
            <li
              class="border-bottom mb-1 cursor-pointer text-xs"
              v-for="item in accounts.slice(0, 5)"
              :key="item.number"
              @click="chooseAccount(item, 'payment')"
            >
              {{ item.number }} - {{ item.description }}
            </li>
          </ul>
        </div>

        <div class="popup-bottons">
          <button type="submit" @click="openModalRegisterPayment = false">
            {{ $t("cancel") }}
          </button>
          <button :disabled="registering" type="submit">
            <span v-if="registering">{{ $t("registering") }}...</span>
            <span v-else>{{ $t("register") }}</span>
          </button>
        </div>
      </form>
    </div>
  </ModalBoxVue>
  <!-- Schedule Invoice sendout modal -->
  <ModalBoxVue
    v-if="openScheduleInvoiceSendoutModal"
    @handleClose="openScheduleInvoiceSendoutModal = false"
    :close="!true"
  >
    <div class="popup-box2">
      <h4 style="text-align: left !important">
        {{ $t("schedule_invoice_send_out") }}
      </h4>
      <div class="container p-3">
        <!-- <form @submit.prevent="updateContractCustomization"> -->
        <form @submit.prevent>
          <div class="customize-cols-scrollar">
            <div class="col-md-8">
              <small>{{ $t("date_to_send") }}</small>
              <Datepicker
                class="form-control"
                v-model="date_to_send"
                format="yyyy-MM-dd"
                :enableTimePicker="false"
                :minDate="today"
              />
            </div>
            <div class="col-md-8">
              <label>{{ $t("invoice_type") }}</label>
              <select v-model="invoice_type" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option value="nox_finans">
                  {{ $t("fortnox_invoice_service") }}
                </option>
                <option value="regular">{{ $t("regular") }}</option>
              </select>
            </div>
            <div class="col-md-8">
              <label>{{ $t("distribution_method") }}</label>
              <select v-model="distribution_method" class="form-control">
                <option value="" selected>{{ $t("choose_an_option") }}</option>
                <option value="email">{{ $t("as_email") }}</option>
                <option value="einvoice">{{ $t("e_invoice") }}</option>
                <option v-if="invoice_type === 'nox_finans'" value="print">
                  {{ $t("letter") }}
                </option>
              </select>
            </div>
            <div class="col-md-8">
              <input
                v-model="bookkeep"
                style="box-shadow: none !important"
                class="checkbox"
                type="checkbox"
                id="bookkeep-id"
                name="bookkeep-name"
              />
              <label for="bookkeep-id"> {{ $t("bookkeep_invoice") }} </label
              ><br />
            </div>
            <div class="col-md-12">
              <input
                v-model="send_failed_nox_as_reg"
                style="box-shadow: none !important"
                class="checkbox"
                type="checkbox"
                id="send_failed_nox_as_reg"
                name="send_failed_nox_as_reg"
              />

              <label for="send_failed_nox_as_reg">
                {{ $t("send_failed_nox_as_reg_2") }} </label
              ><br />
            </div>
          </div>
          <div class="col-md-12 mt-2">
            <span v-if="invoiceData.scheduled_invoice_sending.length">
              <button
                :disabled="scheduling || !canScheduleInvoiceSendout"
                class="btn btn-primary"
                @click="updateScheduleInvoiceSendout()"
              >
                {{ $t("update") }}
              </button>
              <button
                :disabled="scheduling"
                class="btn btn-warning"
                @click="deleteScheduleInvoiceSendout"
              >
                {{ $t("delete") }}
              </button>
            </span>

            <button
              :disabled="scheduling || !canScheduleInvoiceSendout"
              v-else
              class="btn btn-primary"
              @click="addScheduleInvoiceSendout"
            >
              {{ $t("save") }}
            </button>

            <button
              :disabled="scheduling"
              class="btn btn-danger"
              @click="openScheduleInvoiceSendoutModal = false"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ModalBoxVue>
</template>

<script>
import FileSaver from "file-saver";

import debounce from "lodash.debounce";
import { mapGetters } from "vuex";
import { invoiceRow } from "./composables/invoice.create";
import ModalBoxVue from "../../components/ModalBox.vue";
import ModalAlertVue from "../../components/ModalAlert.vue";
import { getCountries } from "./composables/countries";
import ShimmerLoading from "./reusableComponents/ShimmerLoading";
import CustomInvoiceRowComponent from "./reusableComponents/CustomInvoiceRowComponent";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const TODAY = moment().format("YYYY-MM-DD");

export default {
  name: "InvoiceEdit",
  components: {
    ModalAlertVue,
    ModalBoxVue,
    CustomInvoiceRowComponent,
    ShimmerLoading,
    Datepicker,
  },
  data() {
    return {
      sortKey: 0,
      previousVatIncluded: false,
      invoiceData: { invoice_rows: [] },
      helpText: [],
      row: { ...invoiceRow },
      pageLoading: true,
      action: "",
      processing: false,
      processCredit: false,
      countries: getCountries(),
      deliveryWay: [],
      currency: [],
      deliveryTerm: [],
      pricelist: [],
      paymentTerm: [],
      searchCustomer: [],
      showArticle: false,
      searchArticle: [],
      printOutTemplate: [],
      project: [],
      accounts: [],
      costCenter: [],
      units: [],
      showAccount: false,
      showCustomer: false,
      cancelling: false,
      openCancelModal: false,
      openModalSendInvoice: false,
      openFailedInvoices: false,
      failedInvoice: [],
      invoiceType: "fortnox",
      sendMethod: "",
      services: "LEDGERBASE",
      keeping: false,
      sending: false,
      openModalRegisterPayment: false,
      paymentDate: "",
      paymentAmount: "",
      paymentMode: "",
      total_exl_vat: "",
      registering: false,
      showAccountRegister: false,
      // net : 0,
      // vat : 0,
      // totalExVat : 0,
      // gross : 0
      scheduling: false, //flag variable which will make invoice sendout modal buttons disable when request is being processed
      openScheduleInvoiceSendoutModal: false, //flag variable for invoice sendout modal
      date_to_send: TODAY,
      invoice_type: "",
      distribution_method: "",
      bookkeep: false,
      send_failed_nox_as_reg: false,
      submitForm: false,
    };
  },
  watch: {
    "invoiceData.vat_included": function (newVal, oldVal) {
      this.reCalculateValues(null);
    },
    "invoiceData.invoice_rows": function () {
      this.sortKey++; // This will force a re-render of the component
    },
  },
  computed: {
    ...mapGetters({
      GET_SINGLE_INVOICE: "clients/GET_SINGLE_INVOICE",
      CURRENT_USER: "clients/GET_CURRENT_USER",
      CURRENT_CUSTOMER: "clients/GET_CURRENT_CUSTOMER",
      GET_LOCALE: "locale/GET_LOCALE",
    }),
    canScheduleInvoiceSendout() {
      return this.date_to_send && this.invoice_type && this.distribution_method;
    },
    invoiceEligibleForSchedule() {
      return !this.invoiceData.cancelled && !this.invoiceData.fully_paid;
    },
    locale_message() {
      return this.GET_LOCALE === "se" ? "message" : "eng_message";
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    initialize() {
      const { id } = this.$route.params;
      if (id) {
        this.getSingleInvoice(id);
      }
      this.getProjects();
      this.getDeliveryWay();
      this.getCurrency();
      this.getDeliveryTerm();
      this.getPriceList();
      this.getPaymentTerm();
      this.getUnit();
      this.getAccount();
      this.getCostCenter();
      this.getPrintOutTemplate();
    },
    getDiscountedTotal(total, discount, discountType) {
      if (discount) {
        if (discountType === "AMOUNT") {
          total = total - discount;
        } else {
          total =
            total *
            (1 -
              parseFloat(
                this.$services.helpers.removePercentage(discount) / 100
              ));
        }
      }
      return parseFloat(total.toFixed(2));
    },
    getCurrentCustomer(customerNumber) {
      this.$store.dispatch("clients/getCurrentCustomer", customerNumber);
    },
    getProjects() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "projects";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.project = res.data.results;
        })
        .catch((err) => {});
    },
    searchField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterSearch(event.target.value);
      } else {
        this.showCustomer = false;
      }
    }, 500),
    filterSearch(data) {
      const payload = {
        URL:
          this.$services.endpoints.CUSTOMER_ENDPOINT +
          `?search=${data}&customize=no`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.showCustomer = true;
            this.searchCustomer = res.data.data.filter(
              (item) => item.active === true
            );
          }
        })
        .catch((err) => {});
    },
    searchArticleField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterArticleSearch(event.target.value);
      } else {
        this.showArticle = false;
      }
    }, 500),
    searchAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value, "row");
      } else {
        this.showAccount = false;
        this.showAccountRegister = false;
      }
    }, 500),
    searchPaymentAccountField: debounce(function (event) {
      if (event.target.value !== "") {
        this.filterAccountSearch(event.target.value, "payment");
      } else {
        this.showAccount = false;
        this.showAccountRegister = false;
      }
    }, 500),
    filterArticleSearch(data) {
      const payload = {
        URL:
          this.$services.endpoints.ARTICLE_ENDPOINT +
          `?search=${data}&customize=no&active=1`,
      };
      this.$store
        .dispatch("clients/getCustomRequest", payload)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.showArticle = true;
            this.searchArticle = res.data.data.filter(
              (item) => item.active === true
            );
          }
        })
        .catch((err) => {});
    },
    filterAccountSearch(data, type) {
      this.accounts = [];
      const URL =
        this.$services.endpoints.REGISTER_ENDPOINT +
        `accounts?search=${data}&active=1`;
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.accounts = res.data.results;
          if (type === "row") {
            this.showAccount = true;
          } else {
            this.showAccountRegister = true;
          }
        })
        .catch((err) => {});
    },
    chooseArticle(item) {
      this.row.row_id = item.article_number;
      this.row.description = item.description;
      this.row.price = item.sales_price == null ? 0 : item.sales_price;
      // this.row.total = item.
      // this.row.cost_center = item
      if (this.row.price && this.row.delivered_quantity) {
        this.row.total = this.row.delivered_quantity * this.row.price;
      }
      this.row.vat = item.vat;
      this.row.account_number = item.sales_account;
      this.row.unit = item.unit;
      this.showArticle = false;

      this.reCalculateValues(null);
    },
    chooseCustomer(customer) {
      this.invoiceData.customer_number = customer.customer_number;
      this.invoiceData.customer_name = customer.name;
      this.invoiceData.address1 = customer.address1;
      this.invoiceData.address2 = customer.address2;
      this.invoiceData.zip_code = customer.zip_code;
      this.invoiceData.city = customer.city;
      this.invoiceData.organisation_number = customer.organisation_number;
      this.invoiceData.phone1 = customer.phone1;
      this.invoiceData.city = customer.city;
      this.invoiceData.country = customer.country;
      this.invoiceData.delivery_name = customer.delivery_name;
      this.invoiceData.delivery_address1 = customer.delivery_address1;
      this.invoiceData.delivery_address2 = customer.delivery_address2;
      this.invoiceData.delivery_zip_code = customer.delivery_zip_code;
      this.invoiceData.delivery_city = customer.delivery_city;
      this.invoiceData.delivery_date = customer.delivery_date;
      this.invoiceData.phone2 = customer.phone2;
      this.invoiceData.email_address_to = customer.email_address_to;
      this.invoiceData.delivery_city = customer.delivery_city;
      this.invoiceData.delivery_country = customer.delivery_country;
      this.getCurrentCustomer(customer.customer_number);
      this.showCustomer = false;
    },
    chooseAccount(item, type) {
      if (type === "row") {
        this.row.account_number = item.number;
        this.showAccount = false;
      } else {
        this.paymentMode = item.number;
        this.showAccountRegister = false;
      }
      this.reCalculateValues(null);
    },
    // addInvoiceRow() {
    //   if (this.CURRENT_USER.access === "Read") {
    //     return this.$toast.info(this.$t("limited_read_access"));
    //   }
    //   if (
    //     this.invoiceData.booked === true ||
    //     this.invoiceData.cancelled === true
    //   ) {
    //     return this.$services.helpers.notification(
    //       "Invoice already bookkept , new invoice row can not be added",
    //       "error",
    //       this
    //     );
    //   }
    //   if (this.row.row_id === "" || this.row.account_number === "") {
    //     this.$services.helpers.notification(
    //       "Please fill up the invoice row",
    //       "error",
    //       this
    //     );
    //   } else {
    //     this.row.newRow = true;
    //     this.invoiceData.invoice_rows.push(
    //       JSON.parse(JSON.stringify(this.row))
    //     );
    //     this.row = { ...invoiceRow };
    //     this.reCalculateValues(null);
    //   }
    // },
    getSingleInvoice(data) {
      this.pageLoading = false;
      this.$store
        .dispatch("clients/getSingleInvoice", data)
        .then((res) => {
          this.invoiceData = res.data.data;
          this.previousVatIncluded = this.invoiceData.vat_included;
          this.getCurrentCustomer(this.invoiceData.customer_number);
          this.reCalculateValues(null);
          this.pageLoading = false;
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    // calculateAllTotalValue () {
    //     let gross = 0
    //     let vat = 0
    //     let rowVatTotal = 0
    //     let total = 0
    //     this.invoiceData.invoice_rows.forEach(element => {
    //         gross += parseFloat(element.total)
    //         rowVatTotal += parseFloat(element.vat)
    //     })
    //     this.invoiceData.net = parseFloat(gross - rowVatTotal).toFixed(2)
    //     this.invoiceData.gross = parseFloat(gross).toFixed(2)
    //     this.invoiceData.total_vat = parseFloat(rowVatTotal).toFixed(2)
    //     this.invoiceData.total = parseFloat(gross - rowVatTotal).toFixed(2)
    // },
    resCalculateValues() {
      let gross = 0;
      let rowVatTotal = 0;
      let rowVatAmount = 0;

      this.invoiceData.invoice_rows.forEach((element) => {
        let vat_rate;
        if (!element.vat) {
          vat_rate = 0;
        } else {
          vat_rate = element.vat;
        }
        if (this.invoiceData.vat_included) {
          element.total =
            parseFloat(element.delivered_quantity) * parseFloat(element.price);
          element.total = this.getDiscountedTotal(
            element.total,
            element.discount,
            element.discount_type
          );
          // element.vat = element.total * parseFloat(25 / 100)
        } else {
          let rowExVat =
            parseFloat(element.delivered_quantity) * parseFloat(element.price);
          rowExVat = this.getDiscountedTotal(
            rowExVat,
            element.discount,
            element.discount_type
          );

          rowVatAmount =
            (rowExVat * (1 + parseFloat(vat_rate / 100))).toFixed(2) - rowExVat;
          element.total = parseFloat(
            rowExVat * (1 + parseFloat(vat_rate / 100))
          ).toFixed(2);
        }
        gross += parseFloat(element.total);
        rowVatTotal += parseFloat(rowVatAmount);
      });

      this.invoiceData.net = parseFloat(gross - rowVatTotal).toFixed(2);
      this.invoiceData.gross = parseFloat(gross).toFixed(2);
      this.invoiceData.total_vat = parseFloat(rowVatTotal).toFixed(2);
      this.invoiceData.total = parseFloat(gross + rowVatTotal).toFixed(2);
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.invoiceData.invoice_rows.forEach((element) => {
        let vat_amount = 0;
        let vat_rate;
        if (!element.vat) {
          vat_rate = 0;
        } else {
          vat_rate = element.vat;
        }

        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
          element.total_excluding_vat * (1 + vat_rate / 100) -
          element.total_excluding_vat;
        total += parseFloat(element.total_excluding_vat * (1 + vat_rate / 100));
        total_vat += vat_amount;

        if (this.invoiceData.vat_included) {
          element.total =
            parseFloat(element.delivered_quantity) *
            parseFloat(element.price_excluding_vat) *
            parseFloat(1 + parseFloat(vat_rate) / 100);
          element.total = this.getDiscountedTotal(
            element.total,
            element.discount,
            element.discount_type
          );
          element.price = (
            parseFloat(element.price_excluding_vat).toFixed(2) *
            parseFloat(1 + parseFloat(vat_rate) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total =
            parseFloat(element.delivered_quantity).toFixed(2) *
            parseFloat(element.price_excluding_vat).toFixed(2);
          element.total = this.getDiscountedTotal(
            element.total,
            element.discount,
            element.discount_type
          );

          element.total_excluding_vat = parseFloat(
            element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
            element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        let vat_rate;
        if (!item.vat) {
          vat_rate = 0;
        } else {
          vat_rate = item.vat;
        }
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.invoiceData.vat_included) {
            item.total =
              parseFloat(item.delivered_quantity).toFixed(2) *
              parseFloat(item.price_per_unit).toFixed(2);
            item.total = this.getDiscountedTotal(
              item.total,
              item.discount,
              item.discount_type
            );
            item.total_excluding_vat = (
              item.total /
              (1 + parseFloat(vat_rate) / 100)
            ).toFixed(2);
          } else {
            item.total_excluding_vat =
              parseFloat(item.delivered_quantity) *
              parseFloat(item.price_per_unit);
            item.total_excluding_vat = this.getDiscountedTotal(
              item.total_excluding_vat,
              item.discount,
              item.discount_type
            );
            item.total = (
              item.total_excluding_vat *
              (1 + parseFloat(vat_rate) / 100)
            ).toFixed(2);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat).toFixed(
            2
          );

          vat = (item.total - item.total_excluding_vat).toFixed(2);
          total_vat += vat;
        }
      }

      this.invoiceData.gross = parseFloat(total).toFixed(2);
      this.invoiceData.total_vat = parseFloat(total_vat).toFixed(2);
      this.invoiceData.total = parseFloat(total_excluding_vat).toFixed(2);
      this.invoiceData.total_to_pay = parseFloat(total).toFixed(2);
    },
    // reCalculateValues(item = null) {
    //   let gross = 0
    //   let rowVatTotal = 0
    //   if (this.invoiceData.vat_included === true) {
    //     this.invoiceData.invoice_rows.forEach(element => {
    //       let vat = 0
    //       element.total = parseInt(element.delivered_quantity) * parseFloat(parseFloat(element.price) * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(element.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //       vat = parseFloat(element.total) * (parseFloat(element.vat) / 100)
    //       gross += parseFloat(element.total)
    //       rowVatTotal += parseFloat(vat)
    //     })
    //     if (item !== null) {
    //       if (item.delivered_quantity && item.price) {
    //         let total = parseInt(item.delivered_quantity) * parseFloat(parseFloat(item.price) * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(item.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //         let vat = parseFloat(total) * (parseFloat(item.vat) / 100)
    //         gross += parseFloat(total)
    //         rowVatTotal += parseFloat(vat)
    //       }
    //     }
    //   } else {
    //     this.invoiceData.invoice_rows.forEach(element => {
    //       let vat = 0
    //       const rowExVat = parseInt(element.delivered_quantity) * parseFloat(element.price * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(element.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //       vat = rowExVat * parseFloat(element.vat / 100).toFixed(2)
    //       element.total = parseFloat(rowExVat + element.vat).toFixed(2)
    //       gross += parseFloat(element.total)
    //       rowVatTotal += parseFloat(vat)
    //     })
    //     if (item !== null) {
    //       if (item.delivered_quantity && item.price) {
    //         let rowExVat = parseInt(item.delivered_quantity) * parseFloat(item.price * parseFloat(1 - parseFloat(this.$services.helpers.removePercentage(item.discount) / 100).toFixed(2)).toFixed(2)).toFixed(2)
    //         let vat = rowExVat * parseFloat(item.vat / 100).toFixed(2)
    //         let total = parseFloat(rowExVat + item.vat).toFixed(2)
    //         gross += parseFloat(total)
    //         rowVatTotal += parseFloat(vat)
    //       }
    //     }
    //   }
    //   this.invoiceData.gross = parseFloat(gross).toFixed(2)
    //   this.invoiceData.net = parseFloat(gross - rowVatTotal).toFixed(2)
    //   this.invoiceData.total_vat = parseFloat(rowVatTotal).toFixed(2)
    //   this.invoiceData.total = parseFloat(gross - rowVatTotal).toFixed(2)
    //   this.invoiceData.total_to_pay = parseFloat(gross).toFixed(2)
    //   this.total_exl_vat = parseFloat(gross) - parseFloat(rowVatTotal)
    // },
    voidInvoice() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.cancelling = true;
      const payload = {
        URL:
          this.$services.endpoints.INVOICE_ENDPOINT +
          `/${this.$route.params.id}/cancel`,
        data: this.invoiceData,
      };
      this.$store
        .dispatch("clients/customPutRequestInvoice", payload)
        .then((res) => {
          this.cancelling = false;
          this.openCancelModal = false;
          // this.$services.helpers.notification('Invoice void successfully', 'success', this)
          this.$toast.success(this.$t("invoice_voided"));
        })
        .catch((err) => {
          this.cancelling = false;
          this.$services.helpers.notification(
            err.response.data[this.locale_message],
            "error",
            this
          );
        });
    },
    sendInvoice() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.sendMethod === "" || this.invoiceType === "") {
        // this.$services.helpers.notification('Fields values required', 'error', this)
        this.$toast.warning(this.$t("fields_required"));
      } else {
        if (this.invoiceType === "regular") {
          this.sending = true;
          const payload = {
            action: this.sendMethod,
            invoices: [this.invoiceData.document_number],
          };

          this.$store
            .dispatch("clients/updateInvoiceAction", payload)
            .then((res) => {
              this.sending = false;
              this.action = "";
              this.invoiceType = "";
              this.sendMethod = "";
              this.openModalSendInvoice = false;
              // this.$services.helpers.notification('Updated invoice(s) information', 'success', this)
              this.$toast.warning(this.$t("updated_invoice_info"));
            })
            .catch((err) => {
              this.sending = false;
              this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
              );
            });
        } else if (this.invoiceType === "fortnox") {
          this.sending = true;
          const payload = {
            send_method: this.sendMethod,
            service: this.services,
            invoice_number: this.invoiceData.document_number,
          };

          this.$store
            .dispatch("clients/sendInvoiceToNoxFinance", payload)
            .then((res) => {
              this.sending = false;
              this.action = "";
              this.invoiceType = "";
              this.sendMethod = "";
              this.openModalSendInvoice = false;
              if (res.data.failed) {
                this.openFailedInvoices = true;
                this.failedInvoice = res.data.failed;
              } else {
                // this.$services.helpers.notification('Updated invoice(s) information', 'success', this)
                this.$toast.warning(this.$t("updated_invoice_info"));
              }
            })
            .catch((err) => {
              this.sending = false;
              this.$services.helpers.notification(
                err.response.data[this.locale_message],
                "error",
                this
              );
            });
        }
      }
    },
    sendingActions() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.invoiceData.cancelled === true) {
        return this.$services.helpers.notification(
          "Invoice already cancelled ,this action could not be performed",
          "error",
          this
        );
      }
      if (this.action === "schedule") {
        // open the schedule invoice sendout modal and populate it with relevant data
        if (this.invoiceData.scheduled_invoice_sending.length) {
          this.date_to_send =
            this.invoiceData.scheduled_invoice_sending[0].date_to_send;
          this.invoice_type =
            this.invoiceData.scheduled_invoice_sending[0].send_channel;
          this.distribution_method =
            this.invoiceData.scheduled_invoice_sending[0].send_method;
          this.bookkeep =
            this.invoiceData.scheduled_invoice_sending[0].bookkeep;
          this.send_failed_nox_as_reg =
            this.invoiceData.scheduled_invoice_sending[0].send_failed_nox_as_reg;
        }
        this.openScheduleInvoiceSendoutModal = true;
        return;
      }
      if (this.action === "") {
        // this.$services.helpers.notification('Please select an action', 'error', this)
        this.$toast.warning(this.$t("choose_action"));
      } else if (this.action !== "send") {
        if (this.action === "print") {
          this.downloadInvoice();
          return;
        }
        this.sending = true;
        const payload = {
          URL:
            this.$services.endpoints.INVOICE_ENDPOINT +
            `/${this.$route.params.id}/${this.action}`,
          data: this.invoiceData,
        };
        this.$store
          .dispatch("clients/customPutRequestInvoice", payload)
          .then((res) => {
            this.sending = false;
            // this.$services.helpers.notification('Action applied successfully', 'success', this)
            this.$toast.success(this.$t("action_success"));
          })
          .catch((err) => {
            this.sending = false;
            this.$services.helpers.notification(
              err.response.data[this.locale_message],
              "error",
              this
            );
          });
      } else {
        if (this.action === "send") {
          this.openModalSendInvoice = true;
        }
      }
    },
    downloadInvoice() {
      this.sending = true;
      this.$store
        .dispatch("clients/downloadFile", {
          URL:
            this.$services.endpoints.INVOICE_ENDPOINT +
            `/document/download/${this.$route.params.id}`,
        })
        .then((res) => {
          const fileName = this.$route.params.id + ".pdf";
          FileSaver.saveAs(res.data, fileName);
          this.sending = false;
        })
        .catch((err) => {
          this.sending = false;
          this.$services.helpers.notification(err, "error", this);
        });
    },
    bookkeepInvoice() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.invoiceData.cancelled == true) {
        return this.$services.helpers.notification(
          "Cancelled invoice can not be bookkept",
          "error",
          this
        );
      }
      this.keeping = true;
      const payload = {
        URL:
          this.$services.endpoints.INVOICE_ENDPOINT +
          `/${this.$route.params.id}/bookkeep`,
        data: this.invoiceData,
      };
      this.$store
        .dispatch("clients/customPutRequestInvoice", payload)
        .then((res) => {
          this.keeping = false;
          this.getSingleInvoice(this.$route.params.id);
          // this.$services.helpers.notification('Successfully bookkept invoice', 'success', this)
          this.$toast.success(this.$t("invoice_booked"));
        })
        .catch((err) => {
          this.keeping = false;
          this.$services.helpers.notification(
            err.response.data[this.locale_message],
            "error",
            this
          );
        });
    },
    updateInvoiceData(bookkeep = false) {
      this.submitForm = false;
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.invoiceData.cancelled === true) {
        return this.$services.helpers.notification(
          "Cancelled invoice can not be modified",
          "error",
          this
        );
      }
      this.processing = true;
      let data_to_save = {};
      if (this.invoiceData.booked === false) {
        let exclude = [
          "email_information",
          "edi_information",
          "invoice_rows",
          "labels",
          "due_date",
          "invoice_date",
          "not_completed",
          "delivery_date",
          "failed_to_pause",
          "file",
          "fully_paid",
        ];

        let invoiceData = this.invoiceData;
        let email_information = {};
        let edi_information = {};
        let invoice_row = this.invoiceData.invoice_rows;
        let new_invoice_rows = [];
        for (const [key, value] of Object.entries(invoiceData)) {
          if (!exclude.includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = value;
            }
          }
          if (["due_date", "invoice_date", "delivery_date"].includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = moment(value).format("YYYY-MM-DD");
            }
          }
        }
        for (const [key, value] of Object.entries(
          invoiceData.email_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            email_information[key] = value;
          }
        }
        for (const [key, value] of Object.entries(
          invoiceData.edi_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            edi_information[key] = value;
          }
        }
        if (Object.keys(email_information).length > 0) {
          data_to_save["email_information"] = email_information;
        }
        if (Object.keys(edi_information).length > 0) {
          data_to_save["edi_information"] = edi_information;
        }
        invoice_row.map((item) => {
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete item[key];
            }
          }
          new_invoice_rows.push(JSON.parse(JSON.stringify(item)));
        });
        if (this.previousVatIncluded !== this.invoiceData.vat_included) {
          // for each item in invoice_rows, remove vat from price and total fields
          if (this.invoiceData.vat_included === true) {
            new_invoice_rows.forEach((item) => {
              let vat_rate;
              if (!item.vat) {
                vat_rate = 0;
              } else {
                vat_rate = item.vat;
              }
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                  item.price / (1 + vat_rate / 100)
                ).toFixed(2);
                item.total = parseFloat(
                  item.total / (1 + vat_rate / 100)
                ).toFixed(2);
              }
            });
          } else {
            new_invoice_rows.forEach((item) => {
              let vat_rate;
              if (!item.vat) {
                vat_rate = 0;
              } else {
                vat_rate = item.vat;
              }
              // if item.newRow is true, then it's a new row, so don't change price or total
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                  item.price * (1 + vat_rate / 100)
                ).toFixed(2);
                item.total = parseFloat(
                  item.total * (1 + vat_rate / 100)
                ).toFixed(2);
              }
            });
          }
        }
        data_to_save.invoice_rows = new_invoice_rows;
      } else {
        data_to_save["comments"] = this.invoiceData.comments;
        if (
          data_to_save.comments === "" ||
          data_to_save.comments === null ||
          data_to_save.comments === undefined
        ) {
          this.processing = false;
          return this.$services.helpers.notification(
            "Kindly supply valid comment",
            "error",
            this
          );
        }
      }
      this.$store
        .dispatch("clients/updateInvoice", {
          data: data_to_save,
          id: this.$route.params.id,
        })
        .then((res) => {
          this.processing = false;
          this.submitForm = false;
          if (bookkeep) {
            this.bookkeepInvoice();
          } else {
            this.$services.helpers.notification(
              res.data[this.locale_message],
              "success",
              this
            );
          }
          setTimeout(() => {
            window.location.reload(true);
          }, 500);
        })
        .catch((err) => {
          this.processing = false;
          this.errorMessage(err);
        });
    },
    errorMessage(err) {
      try {
        if ([400, 406].includes(err.response.status)) {
          if ("error" in err.response.data) {
            if (Array.isArray(err.response.data.error)) {
              this.$services.helpers.notification(
                err.response.data.error.join(","),
                "error",
                this
              );
            } else if (typeof err.response.data.error === "object") {
              for (const [key, value] of Object.entries(
                err.response.data.error
              )) {
                if (Array.isArray(value)) {
                  this.$services.helpers.notification(
                    key.replace("_", " ") + ": " + value.join(","),
                    "error",
                    this
                  );
                } else {
                  this.$services.helpers.notification(value, "error", this);
                }
                return false;
              }
            } else {
              this.$services.helpers.notification(
                err.response.data.error,
                "error",
                this
              );
            }
          } else if ("message" in err.response.data) {
            if (Array.isArray(err.response.data.message)) {
              this.$services.helpers.notification(
                err.response.data.message.join(""),
                "error",
                this
              );
            } else if (typeof err.response.data.message === "object") {
              if ("message" in err.response.data.message) {
                if ("ErrorInformation" in err.response.data.message.message) {
                  this.$services.helpers.notification(
                    err.response.data.message.message.ErrorInformation.message,
                    "error",
                    this
                  );
                } else {
                  this.$services.helpers.notification(
                    err.response.data.message.message,
                    "error",
                    this
                  );
                }
              } else if ("ErrorInformation" in err.response.data.message) {
                this.$services.helpers.notification(
                  err.response.data.message.ErrorInformation.message,
                  "error",
                  this
                );
              }
            } else {
              this.$services.helpers.notification(
                err.response.data.message,
                "error",
                this
              );
            }
          }
        } else {
          // this.$services.helpers.notification('Something went wrong while processing your request,kindly try again', 'error', this)
          this.$toast.error(this.$t("request_failure"));
        }
      } catch (e) {
        // this.$services.helpers.notification('Action could not be perform', 'error', this)
        this.$toast.error(this.$t("action_failed"));
      }
    },
    getPaymentTerm() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "termsofpayment";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.paymentTerm = res.data.results;
        })
        .catch((err) => {});
    },
    getDeliveryWay() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "wayofdelivery";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.deliveryWay = res.data.results;
        })
        .catch((err) => {});
    },
    getCurrency() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "currencies";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.currency = res.data.results;
        })
        .catch((err) => {});
    },
    getDeliveryTerm() {
      const URL =
        this.$services.endpoints.REGISTER_ENDPOINT + "termsofdelivery";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.deliveryTerm = res.data.results;
        })
        .catch((err) => {});
    },
    getPriceList() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "pricelists";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.pricelist = res.data.results;
        })
        .catch((err) => {});
    },
    getUnit() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "units";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.units = res.data.results;
        })
        .catch((err) => {});
    },
    getAccount() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "accounts";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.accounts = res.data.results;
        })
        .catch((err) => {});
    },
    getCostCenter() {
      const URL = this.$services.endpoints.REGISTER_ENDPOINT + "costcenters";
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.costCenter = res.data.results;
        })
        .catch((err) => {});
    },
    getPrintOutTemplate() {
      const URL =
        this.$services.endpoints.REGISTER_ENDPOINT +
        `printtemplates?type=invoice`;
      this.$store
        .dispatch("clients/getCustomRequest", { URL })
        .then((res) => {
          this.printOutTemplate = res.data.results;
        })
        .catch((err) => {});
    },
    removeRow(item) {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.invoiceData.booked === true) {
        return this.$services.helpers.notification(
          "Invoice already bookkept , invoice row can not be removed",
          "error",
          this
        );
      }
      // const filter = this.invoiceData.invoice_rows.filter(el => el.row_id !== item.row_id)
      // this.invoiceData.invoice_rows = filter
      this.invoiceData.invoice_rows = this.invoiceData.invoice_rows.filter(
        (el) => el.id !== item.id
      );
      this.sortKey++;
      this.reCalculateValues(null);
    },
    openCancelConfirm() {
      this.openCancelModal = true;
    },
    registerPayment() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      if (this.invoiceData.cancelled === true) {
        return this.$services.helpers.notification(
          "Payment can not be registered for cancelled invoice",
          "error",
          this
        );
      }
      if (
        this.paymentDate === "" ||
        this.paymentAmount === "" ||
        this.paymentMode === ""
      ) {
        // this.$services.helpers.notification('All field values required', 'error', this)
        this.$toast.warning(this.$t("fields_required"));
      } else if (!parseFloat(this.paymentAmount)) {
        // this.$services.helpers.notification('Please input a valid amount', 'error', this)
        this.$toast.warning(this.$t("invalid_amount_input"));
      } else {
        this.registering = true;
        const payload = {
          mode_of_payment_account: this.paymentMode,
          payment_date: this.paymentDate,
          invoice_number: this.invoiceData.document_number,
          amount: this.paymentAmount,
        };
        this.$store
          .dispatch("clients/postInvoicePayment", payload)
          .then((res) => {
            this.registering = false;
            this.paymentMode = "";
            this.paymentDate = "";
            this.paymentAmount = "";
            this.openModalRegisterPayment = false;
            // this.$services.helpers.notification('Registered payment invoice', 'success', this)
            this.$toast.success(this.$t("invoice_payment_registered"));
          })
          .catch((err) => {
            this.registering = false;
            this.$services.helpers.notification(
              err.response.data.message,
              "error",
              this
            );
          });
      }
    },
    openCreateCopy() {
      if (this.CURRENT_USER.access === "Read") {
        return this.$toast.info(this.$t("limited_read_access"));
      }
      this.processCredit = true;
      if (this.invoiceData.booked === true) {
        const payload = {
          URL:
            this.$services.endpoints.INVOICE_ENDPOINT +
            `/${this.$route.params.id}/credit`,
          data: this.invoiceData,
        };
        this.$store
          .dispatch("clients/customPutRequestInvoice", payload)
          .then((res) => {
            this.processCredit = false;
            // this.$services.helpers.notification('Action applied successfully', 'success', this)
            this.$toast.success(this.$t("action_success"));
          })
          .catch((err) => {
            this.processCredit = false;
            this.$services.helpers.notification(
              err.response.data.message,
              "error",
              this
            );
          });
      } else {
        // return this.$services.helpers.notification('Invoice can only be credited if booked', 'error', this)
        return this.$toast.warning(this.$t("book_before_credit"));
      }
    },
    openRegisterModal() {
      this.openModalRegisterPayment = true;
    },
    addScheduleInvoiceSendout() {
      this.scheduling = true;
      this.$store
        .dispatch("clients/addScheduleInvoiceSendout", {
          invoices: [this.invoiceData.document_number],
          date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
          send_channel: this.invoice_type,
          send_method: this.distribution_method,
          bookkeep: this.bookkeep,
          send_failed_nox_as_reg: this.send_failed_nox_as_reg,
        })
        .then((res) => {
          this.clearScheduleInvoiceSendoutModalInputs();
          this.initialize();
          this.$services.helpers.notification(
            res.data.message,
            "success",
            this
          );
        })
        .catch((err) => {
          // this.saving = false
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    deleteScheduleInvoiceSendout() {
      this.scheduling = true;
      this.$store
        .dispatch(
          "clients/deleteScheduleInvoiceSendout",
          this.invoiceData.scheduled_invoice_sending[0].id
        )
        .then((res) => {
          // this.saving = false
          this.clearScheduleInvoiceSendoutModalInputs();
          this.initialize();
          this.$services.helpers.notification(
            res.data.message,
            "success",
            this
          );
        })
        .catch((err) => {
          this.saving = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },
    updateScheduleInvoiceSendout() {
      this.scheduling = true;
      this.$store
        .dispatch("clients/updateScheduleInvoiceSendout", {
          id: this.invoiceData.scheduled_invoice_sending[0].id,
          date_to_send: moment(this.date_to_send).format("YYYY-MM-DD"),
          send_channel: this.invoice_type,
          send_method: this.distribution_method,
          bookkeep: this.bookkeep,
          send_failed_nox_as_reg: this.send_failed_nox_as_reg,
        })
        .then((res) => {
          // this.saving = false
          this.clearScheduleInvoiceSendoutModalInputs();
          this.initialize();
          this.$services.helpers.notification(
            res.data.message,
            "success",
            this
          );
        })
        .catch((err) => {
          this.saving = false;
          this.$services.helpers.notification(
            err.response.data.message,
            "error",
            this
          );
        });
    },

    // helper function to clear invoice sendout modal inputs
    clearScheduleInvoiceSendoutModalInputs() {
      this.action = "";
      this.openScheduleInvoiceSendoutModal = false;
      this.date_to_send = TODAY;
      this.invoice_type = "";
      this.send_method = "";
      this.booked = false;
      this.send_failed_nox_as_reg = false;
      this.scheduling = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.justify-content-between- {
  justify-content: space-between !important;
}
.btn-size {
  padding: 0.1rem 1.1rem !important;
}

.content {
  padding: 10px 20px;
  padding-top: 90px;
  background-color: #f6f8fc;
}

.content .headers {
  margin: 30px 0 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content .headers h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #333269;
}

.content .headers h1 ~ h6 {
  font-size: 16px;
  line-height: 24px;
  color: #333269;
  font-weight: 400;
}

.content .invoice-numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  padding: 20px 30px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 20px 0 35px;
}

.content .invoice-numbers h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #333269;
  margin: 0;
}

.content .invoice-numbers .numbers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.content .invoice-numbers .numbers p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #333269;
  margin: 0;
}

.content .invoice-numbers .numbers p:first-of-type {
  margin-right: 20px;
}

.content .box {
  background: #fffdfd;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 5px;
  margin-top: 20px;
}

.content .box.invoice-details h3 {
  background-image: url("./../../assets/images/table/details.png");
  background-repeat: no-repeat;
  background-position: center right;
}

.content .box.invoice-details form img {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.content .box.customer-details h3 {
  background-image: url("./../../assets/images/table/customer.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.delivery-details h3 {
  background-image: url("./../../assets/images/table/delivery.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.invoice h3 {
  background-image: url("./../../assets/images/table/invoice-row.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box.others-details h3 {
  background-image: url("./../../assets/images/table/others.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
}

.content .box:after {
  content: "";
}

.content .box h3 {
  background: #5855d6;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #fffdfd;
  padding: 30px;
  border-radius: 5px 5px 0px 0px;
  margin: 0;
  min-width: 100%;
}

.content .box form {
  padding: 30px;
}

.content .box form .desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 20px;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin: 10px;
}

.content .box form .desc label {
  margin-bottom: 10px;
}

.content .box form .desc input {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  border: none;
  height: 24px;
  width: 95px;
  text-indent: 10px;
  padding-right: 5px;
}

@media (max-width: 991.98px) {
  .content .box form .desc input {
    width: 130px;
  }
}

.content .box form .desc input.big {
  width: 192px;
}

.content .box form .desc input:-moz-read-only {
  background: #c8c7cd;
}

.content .box form .desc input:read-only {
  background: #c8c7cd;
}

.content .box form .desc input:focus {
  outline: none;
}

.content .box form .desc input[type="date"] {
  text-indent: 5px;
}

.content .box form .desc input[type="date"]::-webkit-clear-button,
.content .box form .desc input[type="date"]::-webkit-inner-spin-button,
.content .box form .desc input[type="date"]::-webkit-calendar-picker-indicator,
.content .box form .desc input[type="date"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

.content .box form .desc input[type="number"] {
  width: 55px;
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}

.content .box form .desc input[type="number"]::-webkit-outer-spin-button,
.content .box form .desc input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.content .box form .desc select {
  background: #f0eff5;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  width: 95px;
  height: 24px;
  border: none;
  text-indent: 5px;
}

.content .box form .desc select.big {
  width: 192px;
}

@media (max-width: 991.98px) {
  .content .box form .desc select.big {
    width: 130px;
  }
}

.content .box form .desc .radio {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: #333269;
  border-radius: 4px;
  color: #d2d1d7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px;
}

.content .box form .desc .radio input {
  display: none;
}

.content .box form .desc .radio input:checked + label {
  background: #f6f8fc;
  color: #000000;
}

.content .box form .desc .radio label {
  margin: 0;
  cursor: pointer;
  padding: 1px 11.5px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}

.content .box form .desc .radio label:first-of-type {
  margin-right: 10px;
}

.content .box.customer-details .desc {
  margin-right: 5%;
}

.content .box table {
  width: 100%;
  margin: 0px auto 10px;
  background: #fff;
}

.content .box table thead th {
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  background: #f6f8fc;
  padding: 20px 15px;
  text-align: center;
  white-space: nowrap;
}

.content .box table thead th:nth-of-type(1),
.content .box table thead th:nth-of-type(2) {
  text-align: left;
}

.content .box table thead th:nth-of-type(2) {
  padding-right: 80px;
}

.content .box table tbody tr {
  position: relative;
}

.content .box table tbody tr:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0px;
  bottom: 0;
}

.content .box table tbody tr:last-of-type:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #cbd4e1;
  left: 0;
  bottom: 0;
}

.content .box table tbody tr td {
  vertical-align: middle;
  padding: 10px 15px;
  position: relative;
}

.content .box table tbody tr td input {
  background: #e6e5eb;
  -webkit-box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 1px 1px rgba(9, 30, 66, 0.25),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  height: 22px;
  border: none;
  display: block;
  padding: 1px 5px;
  margin: 0 auto;
  text-align: center;
}

.content .box table tbody tr td input:focus {
  border: none;
  outline: none;
}

.content .box table tbody tr td input.big {
  max-width: 188px;
}

.content .box table tbody tr td input.mid {
  max-width: 64px;
}

.content .box table tbody tr td input.small {
  width: 31px;
}

.content .box table tbody tr td:not(:last-of-type):after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #cbd4e1;
  right: 0;
  top: 0;
}

.content .box table tbody tr td:nth-of-type(1) input,
.content .box table tbody tr td:nth-of-type(2) input {
  margin-left: 5px;
  text-align: left;
}

.content .box button {
  background: #5855d6;
  width: 126px;
  height: 38px;
  -webkit-box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 3%;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .box button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .box textarea {
  background: #f0eff5;
  border-radius: 5px;
  height: 78px;
  resize: none;
  width: 214px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  border: none;
  padding: 10px;
}

@media (max-width: 991.98px) {
  .content .box textarea {
    width: 90%;
  }
}

.content .box textarea:focus {
  border: none;
  outline: none;
}

.content .box.others-details {
  min-height: 320px;
}

.content .box.others-details form {
  padding: 30px 20px 0;
}

.content .box.price {
  padding: 30px 20px;
}

.content .box.price ul {
  margin: 0;
}

.content .box.price ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .box.price ul li .border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -moz-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 10px;
  margin-top: 2px;
}

.content .box.price ul li:not(:first-of-type) {
  margin-top: 10px;
}

.content .box.price ul li:last-of-type {
  margin-top: 0;
}

.content .box.price ul li:last-of-type span:first-of-type {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content .box.price ul li:last-of-type span:last-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:first-of-type {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .box.price ul li:not(:last-of-type) span:last-of-type {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.content .box.price ul {
  min-width: 162px;
}

.content .send {
  margin: 15px 0 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.content .send label {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.content .send select {
  margin: 0 15px 0 10px;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  border: none;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 10px;
  height: 30px;
}

.content .send select:focus {
  outline: none;
}

.content .send button {
  background: #5855d6;
  border-radius: 4px;
  padding: 0 16px;
  border: none;
  color: #f6f8fc;
  font-size: 14px;
  line-height: 22px;
  height: 30px;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .send button:hover {
  background-color: #4947b0;
  color: #fff;
}

.content .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.content .buttons button,
.content .buttons a {
  border-radius: 4px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f6f8fc;
  margin: 5px 0;
  padding: 0.6rem 1.1rem;
}

.content .buttons button:not(:first-child),
.content .buttons a:not(:first-child) {
  margin-left: 10px;
}

.content .buttons button:nth-child(1),
.content .buttons a:nth-child(1) {
  color: #64748b;
  border: 1px solid #64748b;
  background: #f6f8fc;
  text-decoration: none;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(1):hover,
.content .buttons a:nth-child(1):hover {
  color: #4b5768;
  border-color: #4b5768;
  background-color: #eaf0fa;
}

.content .buttons button:nth-child(2),
.content .buttons a:nth-child(2) {
  background: #40e7fa;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(2):hover,
.content .buttons a:nth-child(2):hover {
  background-color: #40e7fa;
  color: #fff;
}

.content .buttons button:nth-child(3),
.content .buttons a:nth-child(3) {
  background: #2ed573;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(3):hover,
.content .buttons a:nth-child(3):hover {
  background-color: #2ed573;
  color: #fff;
}

.content .buttons button:nth-child(4),
.content .buttons a:nth-child(4) {
  background: #f74949;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(4):hover,
.content .buttons a:nth-child(4):hover {
  background-color: #f74949;
  color: #fff;
}

.content .buttons button:nth-child(5),
.content .buttons a:nth-child(5) {
  background: #f5db17;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(5):hover,
.content .buttons a:nth-child(5):hover {
  background-color: #f5db17;
  color: #fff;
}

.content .buttons button:nth-child(6),
.content .buttons a:nth-child(6) {
  background: #4793f7;
  -webkit-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.content .buttons button:nth-child(6):hover,
.content .buttons a:nth-child(6):hover {
  background-color: #4793f7;
  color: #fff;
}

.content .vertical-desc .desc {
  margin-bottom: 17px;
}

.currency-container {
  position: relative;
}

.currency {
  position: absolute;
  top: 41px;
  right: 10px;
}

.input-select {
  background: #f0eff5;
  box-shadow: 0px 1px 1px rgb(9 30 66 / 25%), 0px 0px 1px rgb(9 30 66 / 31%);
  border-radius: 4px;
  border: none;
  text-indent: 5px;
}
.content .box form .col .desc {
  width: auto;
}

// TODO: removing default styles of date picker
// .dp__input_icon_pad {
//   box-shadow: none !important;
//   background: transparent !important;
//   width: 100% !important;
// }
</style>
